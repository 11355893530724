import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dateField", "birthdayField"]

  toggle(event) {
    this.dateFieldTarget.style.display = event.target.checked ? "block" : "none"
    this.birthdayFieldTarget.required = event.target.checked
  }
}
