import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="price"
export default class extends Controller {
  static targets = ["image", "form"]

  connect() {
    this.formTarget.addEventListener('submit', event => this.validateSelection(event))
  }

  select(event) {
    const image = event.target
    // Prevent the default action to ensure the radio button state is controlled manually
    event.preventDefault();

    // Find the radio button related to the clicked image
    const radioButton = event.currentTarget.querySelector('input[type="radio"]');

    // Check the radio button
    if (radioButton) {
      radioButton.checked = true;
    }

    this.imageTargets.forEach(element => {
      element.classList.remove("active")
    })
    image.classList.add("active")
    this.formTarget.classList.remove("error-wrapper") // Remove error class when a selection is made
  }

  validateSelection(event) {
    const hasActive = this.imageTargets.some(element => element.classList.contains("active"))
    if (!hasActive) {
      event.preventDefault() // Prevent form submission
      this.formTarget.classList.add("error-wrapper") // Add error class to the form
    }
  }
}
