import Flatpickr from 'stimulus-flatpickr'

export default class extends Flatpickr {
  initialize() {
    this.config = {
      maxDate: 'today',
      disableMobile: true,
      parseDate: this.customParseDate,
    }
  }

  customParseDate(dateString, format) {
    // Remove any leading/trailing whitespace
    dateString = dateString.trim();

    let day, month, year;

    // Try to match dd.mm.yyyy or d.m.yyyy format
    let dotMatch = dateString.match(/^(\d{1,2})\.(\d{1,2})\.(\d{4})$/);
    if (dotMatch) {
      [, day, month, year] = dotMatch.map(Number);
    }
    // Try to match ddmmyyyy format
    else if (/^\d{8}$/.test(dateString)) {
      day = parseInt(dateString.substr(0, 2), 10);
      month = parseInt(dateString.substr(2, 2), 10);
      year = parseInt(dateString.substr(4, 4), 10);
    }
    else {
      // If no format matches, return null
      return null;
    }

    // Validate day, month, and year
    if (month < 1 || month > 12) return null;

    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) return null;

    if (year < 1900 || year > new Date().getFullYear()) return null;

    // JavaScript months are 0-indexed
    return new Date(year, month - 1, day);
  }
}
